import { $EventBus } from '@/main';
import textTransform from '@/helpers/textTransform.js';
import CDatePicker from '@/components/global/CDatePicker.vue';

export default {
	name: 'EvaluationDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		extinguisher: {
			type: Object,
			required: false,
		},
	},
	data: () => ({
		valid: null,
		tool: null,
		rules: [(v) => !!v || 'Campo requerido'],
	}),
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.tool = this.extinguisher;
			}
		},
	},
	methods: {
		textTransform,
		
		isFormCompleted() {
			const hasIncompleteEvaluation = this.tool.criterias.some((item) => {
				if (item.value == undefined) {
					return true;
				} else if (item.value == 'NO' && !item.actions) {
					return true;
				} else if (item.value == 'NO' && !item.responsible_id) {
					return true;
				} else if (item.value == 'NO' && !item.date) {
					return true;
				} else {
					return false;
				}
			});
			return !hasIncompleteEvaluation;
		},

		hasObservation() {
			const result = this.tool.evaluations.some((item) => {
				return item.value && item.value == 'MALO';
			});
			return result;
		},

		saveEvaluation() {
			const isCompletedForm = this.$refs.form.validate();
			if (isCompletedForm) {
				const data = {
					...this.tool,					
					hasObservation: this.hasObservation(),
				};
				this.$emit('addExtinguishers', JSON.parse(JSON.stringify(data)));
				this.closeEvaluation();
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Evaluación');
			}
		},

		closeEvaluation() {
			this.$nextTick(() => {
				this.$refs.form.reset();
				this.tool = null;
				this.dialog = false;
			});
		},
	},
	components: {
		CDatePicker
	},
};
