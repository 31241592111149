var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection ? _vm.inspection.code : '')}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection ? _vm.textTransform(_vm.inspection.name) : '')}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader() || _vm.isLoadingSave},on:{"click":function($event){return _vm.saveRegister('DRAFT')}}},[_vm._v(" Guardar como borrador ")])],1)],1),_c('v-card',{staticClass:"my-10",attrs:{"elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-6"},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader()
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[(_vm.inspection)?_c('form-header',{attrs:{"headers":_vm.inspection.headers}}):_vm._e(),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_c('v-stepper-step',{attrs:{"step":"2","complete":"","editable":"","edit-icon":_vm.isCompletedExtinguisher()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"color":_vm.steps.current == 2
									? 'primary'
									: _vm.isCompletedExtinguisher()
									? 'success'
									: 'error'}},[_vm._v(" Lista de extintores ")]),_c('v-stepper-content',{key:2 + 'vsc',attrs:{"step":2}},[_c('element-table',{on:{"openDialog":function($event){return _vm.openDialog()},"editExtinguishers":_vm.editExtinguishers},model:{value:(_vm.extinguishers),callback:function ($$v) {_vm.extinguishers=$$v},expression:"extinguishers"}})],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"pa-8 d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader() || _vm.isLoadingSave},on:{"click":function($event){return _vm.saveRegister('DRAFT')}}},[_vm._v(" Guardar como borrador ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"green","loading":_vm.isLoadingSave,"disabled":!_vm.isCompletedInspectons || _vm.isLoadingDraft},on:{"click":function($event){return _vm.saveRegister('PENDING')}}},[_vm._v(" Finalizar ")])],1)])],1)],1)],1),_c('evaluation-dialog',{attrs:{"extinguisher":_vm.currentExtinguisher},on:{"closeDialog":function($event){return _vm.closeDialog()},"addExtinguishers":_vm.addExtinguishers},model:{value:(_vm.evaluationToolDialog),callback:function ($$v) {_vm.evaluationToolDialog=$$v},expression:"evaluationToolDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }