import { mapActions } from 'vuex';
import CbodyDatePicker from '@/components/inspections/inputs/CbodyDatePicker.vue';
import { $EventBus } from '@/main';

export default {
	name: 'EvaluationTable',
	props: {
		value: {
			type: Array,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		tool: null,
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Ubicación',
				value: 'ubication',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: '',
				value: 'status',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
	}),
	computed: {
		extinguishers: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.tool = this.toolEvaluation;
				if (Array.isArray(this.usersActive) && this.usersActive.length == 0) {
					this.getUsersActive();
				}
			}
		},
	},
	methods: {
		...mapActions('security', ['getUsersActive']),

		isFormCompleted() {
			const hasIncompleteEvaluation = this.tool.criterias.some((item) => {
				if (item.value == undefined) {
					return true;
				} else if (item.value == 'NO' && !item.actions) {
					return true;
				} else if (item.value == 'NO' && !item.responsible_id) {
					return true;
				} else if (item.value == 'NO' && !item.date) {
					return true;
				} else {
					return false;
				}
			});
			return !hasIncompleteEvaluation;
		},

		hasObservation() {
			const result = this.tool.criterias.some((item) => {
				return item.value && item.value == 'NO';
			});
			return result;
		},

		saveEvaluation() {
			if (this.isFormCompleted()) {
				this.tool.hasObservation = this.hasObservation();
				this.tool.isEvaluated = true;
				this.$emit('saveToolEvaluation', this.tool);
				this.toolDialog = false;
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Evaluación');
			}
		},

		removeEvaluation(id) {
			const index = this.extinguishers.findIndex(
				(element) => element.currentId == id
			);
			if (index >= 0) this.extinguishers.splice(index, 1);
		},

		editExtinguishers(item) {
			this.$emit('editExtinguishers', JSON.parse(JSON.stringify(item)));
		},
	},
	components: {
		CbodyDatePicker,
	},
};
